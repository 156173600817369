.radiate-theme.text-field-container {

  .content {
    textarea {
      border: none;
      width: 100%;
      font-size: 0.9rem;
      resize: none;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(0, 0, 0, 0.333);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(0, 0, 0, 0.333);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(0, 0, 0, 0.333);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(0, 0, 0, 0.333);
      }
    }

    textarea:active, textarea:focus {
      outline: none;
    }
  }
}

.ace_placeholder {
  white-space: pre-wrap;
}