.radiate-theme.segmented-select-container {
  background: white;
  display: inline-flex;
  height: 30px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  .option {
    padding: 0 16px;
    font-size: 0.8rem;
    line-height: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    color: #000000;

    &:last-child {
      border-right: none;
    }

    &.selected {
      background: #0063f0;
      color: white;
    }

    &.disabled {
      background: #f8f8f8;
      color: #bbbbbc;
      cursor: auto;
    }
    &.readOnly {
      background: #1D70A2;
      color: #929292;
      cursor: auto;
    }
  }

  &.disabled {
    border-color: rgba(0, 0, 0, 0.05);

    .option {
      color: rgba(0, 0, 0, 0.15);
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      pointer-events: none;
      cursor: auto;

      &.selected {
        background: #f8f8f8;
        color: #bbbbbc;
        cursor: auto;
      }
    }
  }

  &.readOnly {
    .option {
      color: #929292;
      background-color: #f0f0f0;
      cursor: auto;

      &.selected {
        background: #0063f0;
        color: #ffffff;
        cursor: auto;
      }
    }
  }
}
