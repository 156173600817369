.radiate-theme.field-container.toggle-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.disabled {
    .label {
      color: #a5a5a6;
    }
  }

  .label {
    margin-bottom: 0;
    color: #777777;
    font-size: 0.8rem;
    transition: opacity 0.2s linear;
  }
  >.content {
    padding-left: 8px;
    border-bottom: none;
    margin-right: 0;
  }

  .error-container {
    margin-top: 2px;
    color: #cc0000;
    font-size: 0.7rem;
  }

  &.focused {
    .label {
      color: #0063f0;
    }
    >.content {
      border-bottom: none;
    }
    &.danger {
      .label {
        color: #cc0000;
      }
    }
  }

  &.danger {
    .label {
      color: #cc0000;
    }
  }
}