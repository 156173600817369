.radiate-theme.toggle-container {
  width: 44px;
  height: 26px;
  padding: 3px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  transition: background-color linear 0.1s;
  position: relative;

  &.on {
    .handle {
      transform: translateX(18px);
    }
  }

  &.disabled {
    // pointer-events: none;
    cursor: auto;

    .handle {
      background-color: transparentize(white, 0.3);
    }
  }

  .handle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 4px 1px transparentize(#333, 0.9);
    background-color: white;
    user-select: none;
    -webkit-user-select: none;
    transition: transform ease-out 0.1s;
  }
}
