
.facebook-persistent-menu-settings-container {
  padding: 0 24px;
  border-radius: 6px;
  border: 1px solid #f0f2f7;
  margin-bottom: 24px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);

  // :last-child {
  //   margin-bottom: 0px;
  // }

  .facebook-locale-display-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;

    .facebook-locale-title {
      font-weight: bold;
    }

    .facebook-locale-selector {
      width: 400px;
      display: inline-block;
    }

    .button-wrapper {
      margin-left: 16px;
      align-items: center;
      display: flex;

      >.icon {
        padding: 0 16px;
      }
    }
  }

  .facebook-locale-wrapper {
    border: 1px solid #f0f2f7;
    border-radius: 6px;
    margin-bottom :24px;

    .facebook-locale-wrapper-top-bar {
      position: relative;
      padding: 8px;
      background: #ffffff !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f2f7;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .persistent-menu-settings-wrapper {
    min-height: 320px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    

    &.basic {
      display: flex;

      .preview-container {
        width: 50%;
        background: #f0f2f7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 6px;

        .pm-container {
          width: 320px;
          background: #f8f8f8;
          margin: 16px 0;
          >.top-bar {
            padding: 8px 8px 0 8px;
            display: flex;
            justify-content: center;
            .bar {
              width: 40px;
              height: 5px;
              display: inline-block;
              border-radius: 2.5px;
              background: #ddd;
            }
          }
          .pm-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            .fake-input {
              height: 32px;
              border-radius: 16px;
              background: #eee;
              width: calc(100% - 16px);
              margin: 8px;
              color: #aaa;
              padding: 0 16px;
              box-sizing: border-box;
              line-height: 32px;
              font-size: 0.9rem;   
            }

            .nav-bar {
              width: 100%;
              position: relative;
              text-align: center;
              height: 44px;

              span {
                line-height: 44px;
              }

              .prev-icon-container {
                position: absolute;
                top: 6px;
                left: 8px;
                background: #eee;
                width: 32px;
                height: 32px;
                display: flex;
                border-radius: 16px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .icon {
                  color: #ccc;
                }
              }
            }
          }

          .buttons-container {
            width: 100%;
          }
          
          .pm-button {
            height: 44px;
            background: #f8f8f8;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.9rem;
            cursor: pointer;
            padding: 8px;
            color: #222;

            &.general {
              justify-content: flex-start;
            }

            .icon {
              color: #ccc;
            }

            .next-icon-container {
              background: #eee;
              width: 32px;
              height: 32px;
              display: flex;
              border-radius: 16px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }

            &.selected {
              background: #1D70A2;
              color: white;

              .next-icon-container {
                background: #1B4353;
              }              
            }

            .drag-handle {
              margin-right: 8px;
            }

            &.nested {
              .align-left {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      .settings-container {
        width: 50%;
        box-sizing: border-box;
        background: white;
        padding: 16px;
        border-bottom-right-radius: 6px;

        .title {
          font-weight: 600;
        }

        >.top-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        
          .remove-button {
            border: 1px solid #ddd;
            border-radius: 6px;
            padding-left: 8px;
            padding-right: 8px;
          }
        }

        .field-container.button-type {
          margin-top: 16px;
          .label {
            font-size: 0.8rem;
            color: #777777;
            margin-bottom: 2px;
          }

         
        }

        .field-container.webview-height-select{
          margin-top: 16px;
          .label {
            font-size: 0.8rem;
            color: #777777;
            margin-bottom: 2px;
          }
        }

        .button-type-settings-container {
          margin-top: 16px;

          .messenger-extensions-toggle {
            margin: 16px 12px 16px 0;
          }

          .webview-share-button-toggle {
            margin: 16px 12px 16px 0;
          }
        }
      }
    }

    &.advanced {
      .advanced-pm-editor {
        height: 457px;
      } 
    }
  }
}